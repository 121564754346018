import { Button, size } from '@everlywell/leaves';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, config, animated as A } from 'react-spring';
import { sanitize } from 'utils/domHelpers';
import { CTA } from 'utils/types';

import * as S from '../../LatestUpdatesSection.styles';

export type SectionContentProps = {
  uid: string;
  title: string;
  headline: string;
  subHeadline: string;
  subHeadlineDescription?: string;
  description?: string;
  mainCta: CTA;
  onClick?: (e: Event) => void;
  showDescriptionOnMobile?: boolean;
  headlineRef: React.LegacyRef<HTMLDivElement>;
  headlineInView: boolean;
};

/**
 * Render the copy content for the latest updates section.
 */
function SectionContent(props: SectionContentProps): JSX.Element {
  const {
    uid,
    title,
    headline,
    subHeadline,
    subHeadlineDescription,
    description,
    mainCta,
    onClick,
    showDescriptionOnMobile,
    headlineRef,
    headlineInView,
  } = props;

  const [titleRef, titleInView] = useInView({ triggerOnce: true });

  // triggered from scrolling title into view
  const titleStyles = useSpring({
    opacity: titleInView ? 1 : 0,
    transform: titleInView ? 'translateY(0)' : `translateY(${size.xl1}px)`,
    config: { ...config.slow, friction: 75 },
  });

  // triggered from scrolling headline into view
  const headlineStyles = useSpring({
    opacity: headlineInView ? 1 : 0,
    transform: headlineInView ? 'translateY(0)' : `translateY(${size.xl2}px)`,
    config: { ...config.slow, friction: 75 },
  });

  // triggered from scrolling headline into view
  const buttonStyles = useSpring({
    opacity: headlineInView ? 1 : 0,
    transform: headlineInView ? 'translateY(0)' : `translateY(${size.xl2}px)`,
    config: { ...config.slow, friction: 75 },
  });

  return (
    <S.ContentWrapper>
      <div ref={titleRef} />
      <A.div style={titleStyles}>
        {title ? <S.Title text={title} /> : null}
      </A.div>
      <div ref={headlineRef} />
      <A.div style={headlineStyles}>
        {headline ? <S.Headline>{headline}</S.Headline> : null}
      </A.div>
      <A.div style={headlineStyles}>
        {subHeadline ? <S.SubHeadline>{subHeadline}</S.SubHeadline> : null}
      </A.div>
      <A.div>
        {description ? (
          <S.Description
            body={description}
            showDescriptionOnMobile={showDescriptionOnMobile}
          />
        ) : null}
        {subHeadlineDescription ? (
          <S.CalloutText
            dangerouslySetInnerHTML={{
              __html: sanitize(subHeadlineDescription),
            }}
          />
        ) : null}
      </A.div>
      <A.div style={buttonStyles}>
        {mainCta ? (
          <Button
            appearance={mainCta.appearance ? mainCta.appearance : 'primary'}
            href={mainCta.url}
            data-testid={`${uid}-cta`}
            onClick={(e) => {
              onClick?.(e);
            }}
          >
            {mainCta.text}
          </Button>
        ) : null}
      </A.div>
    </S.ContentWrapper>
  );
}

export default SectionContent;
