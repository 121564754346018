import { Row, Col, Container } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, config, animated as A } from 'react-spring';
import { CTA, IGatsbyImage } from 'utils/types';

import SectionContent from './components/SectionContent';
import * as S from './LatestUpdatesSection.styles';

export enum LayoutVariation {
  Default,
  Reversed,
}

export type LatestUpdatesSectionProps = {
  uid?: string;
  title: string;
  headline: string;
  subHeadline: string;
  subHeadlineDescription?: string;
  description?: string;
  mainCta: CTA;
  desktopImage?: IGatsbyImage;
  mobileImage?: IGatsbyImage;
  layoutVariation: LayoutVariation;
  onClick?: (e: Event) => void;
  backgroundColor?: 'Primary' | 'Secondary';
  showDescriptionOnMobile?: boolean;
};

export const LatestUpdatesSection = ({
  uid = `latestUpdatesSection`,
  title,
  headline,
  subHeadline,
  subHeadlineDescription,
  description,
  mainCta,
  desktopImage,
  mobileImage,
  layoutVariation = LayoutVariation.Default,
  onClick,
  backgroundColor,
  showDescriptionOnMobile,
}: LatestUpdatesSectionProps) => {
  const [desktopImageOffset, setDesktopImageOffset] = useState<number>(0);

  const desktopImageRef = useRef<HTMLDivElement>(null);
  // get the desktop Image's height and set for use in desktopImageTransition spring
  useEffect(() => {
    if (desktopImageRef && desktopImageRef.current) {
      setDesktopImageOffset(desktopImageRef.current.clientHeight);
    }
  }, [desktopImageRef]);

  const [sectionRef, sectionInView] = useInView({ triggerOnce: true });
  const [headlineRef, headlineInView] = useInView({ triggerOnce: true });

  // triggered from scrolling headline into view
  const desktopImageStyles = useSpring({
    transform: headlineInView
      ? 'translateY(0)'
      : `translateY(${desktopImageOffset * 1.75}px)`,
    config: { ...config.slow, friction: 75 },
  });

  // triggered from scrolling headline into view
  const imageZoomStyles = useSpring({
    transform: headlineInView ? 'scale(1)' : 'scale(1.75)',
    config: { ...config.slow, friction: 75 },
  });

  const mobileImageZoomStyles = useSpring({
    transform: sectionInView ? 'scale(1)' : 'scale(1.3)',
  });

  // TODO: these need to live outside of this component as this can
  // cause unwanted side effects
  const MobileImage = () =>
    mobileImage ? (
      <S.MobileImageWrapper>
        <A.div style={mobileImageZoomStyles}>
          <Img
            image={mobileImage.gatsbyImageData}
            alt={mobileImage.description || ''}
          />
        </A.div>
      </S.MobileImageWrapper>
    ) : null;

  const DesktopImage = () =>
    desktopImage ? (
      <S.DesktopImageWrapper ref={desktopImageRef}>
        <S.AnimatedImageWrapper style={desktopImageStyles}>
          <A.div style={imageZoomStyles}>
            <Img
              image={desktopImage.gatsbyImageData}
              alt={desktopImage.description || ''}
            />
          </A.div>
        </S.AnimatedImageWrapper>
      </S.DesktopImageWrapper>
    ) : null;

  return (
    <SectionWrapper
      id={uid}
      ref={sectionRef}
      data-testid={`${uid}-section`}
      backgroundColor={backgroundColor}
    >
      {/* Layout variation to allow for minor polymorphism.
          The Row component has a `reversed` prop, but it does not account for
          reversing the width of the columns, in only reorders the Columns.
          This is a good candidate for updating to CSS grid.
      */}
      {layoutVariation === LayoutVariation.Default ? (
        <>
          <MobileImage />
          <Container>
            <Row middle="xs">
              <Col md={5}>
                <S.SectionContentWrapper>
                  <SectionContent
                    description={description}
                    headline={headline}
                    headlineInView={headlineInView}
                    headlineRef={headlineRef}
                    mainCta={mainCta}
                    onClick={onClick}
                    showDescriptionOnMobile={showDescriptionOnMobile}
                    subHeadline={subHeadline}
                    subHeadlineDescription={subHeadlineDescription}
                    title={title}
                    uid={uid}
                  />
                </S.SectionContentWrapper>
              </Col>
              <Col md={7}>
                <DesktopImage />
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
      {layoutVariation === LayoutVariation.Reversed ? (
        <>
          <MobileImage />
          <Container>
            <Row middle="xs">
              <Col md={7}>
                <DesktopImage />
              </Col>
              <Col md={5}>
                <S.ReversedSectionContentWrapper>
                  <SectionContent
                    description={description}
                    headline={headline}
                    headlineInView={headlineInView}
                    headlineRef={headlineRef}
                    mainCta={mainCta}
                    onClick={onClick}
                    showDescriptionOnMobile={showDescriptionOnMobile}
                    subHeadline={subHeadline}
                    subHeadlineDescription={subHeadlineDescription}
                    title={title}
                    uid={uid}
                  />
                </S.ReversedSectionContentWrapper>
              </Col>
            </Row>
          </Container>
        </>
      ) : null}
    </SectionWrapper>
  );
};

export default LatestUpdatesSection;
