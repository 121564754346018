import { mediaQueries, size, H2, typography, colors } from '@everlywell/leaves';
import { animated } from 'react-spring';
import styled from 'styled-components';

import { MarkdownContent } from '../../Contentful/MarkdownContent';
import SectionTitle from '../../Enterprise/SectionTitle';

export const MobileImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;
export const DesktopImageWrapper = styled.div`
  width: 100%;
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    overflow: hidden;
    box-shadow: 0 7px 30px -10px #dce4e5;
  }
`;

export const AnimatedImageWrapper = styled(animated.div)``;

export const ContentWrapper = styled.div`
  padding-top: ${size.xl1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 14px;
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: left;
  }
`;

export const Headline = styled(H2)`
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xs1}px;
  }
`;

export const SubHeadline = styled.p`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    ${typography.bodyText};
    margin-bottom: ${size.xl1}px;
  }
`;

// TODO: this font style doesn't exist in DLS
export const CalloutText = styled.div`
  ${typography.h5Text};
  color: ${colors.gray5};
`;

interface DescriptionProps {
  showDescriptionOnMobile?: boolean;
}

export const Description = styled(MarkdownContent)<DescriptionProps>`
  display: ${(props) => (props.showDescriptionOnMobile ? 'block' : 'none')};
  ${typography.bodyText};
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    margin-bottom: ${size.xl1}px;
  }
`;

export const SectionContentWrapper = styled.div`
  padding-right: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-right: ${size.xl3}px;
  }
`;

export const ReversedSectionContentWrapper = styled.div`
  padding-left: 0;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-left: ${size.xl3}px;
  }
`;
