import React from 'react';

import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import LatestUpdatesSection, {
  LayoutVariation,
} from 'components/Homepage/LatestUpdatesSection';
import { CTA, IGatsbyImage } from 'utils/types';

export interface LatestUpdatesContainerProps {
  content: {
    slug: string;
    title: string;
    headline: string;
    subHeadline: string;
    mainCta: CTA;
    desktopImages: IGatsbyImage[];
    mobileImages: IGatsbyImage[];
    componentVariation?: string;
  };
}

export const LatestUpdatesContainer: React.FC<LatestUpdatesContainerProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }

  const {
    slug,
    title,
    headline,
    subHeadline,
    mainCta,
    desktopImages: rawDesktopImages,
    mobileImages: rawMobileImages,
    componentVariation = 'Default',
  } = content;

  const trackCtaClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: slug || 'Latest Updates CTA',
      },
    });
  };

  // Eww. We need to cast the string to an Enum, though.
  const layoutVariation =
    LayoutVariation[componentVariation as keyof typeof LayoutVariation];

  return (
    <LatestUpdatesSection
      title={title}
      headline={headline}
      subHeadline={subHeadline}
      mainCta={mainCta}
      desktopImage={rawDesktopImages ? rawDesktopImages[0] : undefined}
      mobileImage={rawMobileImages ? rawMobileImages[0] : undefined}
      layoutVariation={layoutVariation}
      onClick={trackCtaClick}
    />
  );
};

export default LatestUpdatesContainer;
